<template>
  <div class="rounded-3xl text-center grid grid-cols-1">
    <img :alt="icon" class="m-auto h-12 w-12" :src="imageUrl">
    <span class="font-medium pt-4 text-xl">{{ title }}</span>
  </div>
</template>

<script>
import {toRefs, computed} from 'vue'

export default {
  name: "TechCard",
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
  },
  setup(props) {

    const {icon} = toRefs(props)

    const imageUrl = computed(() => {
      return require('@/assets/icons/tools/' + icon.value + '.svg')
    });

    return {
      imageUrl
    }
  }
}
</script>