<template>
  <div class="bitelife-admin-page">
    <!-- Hero -->
    <section class="bitelife-admin-page__hero">
      <div class="m-auto grid grid-cols-1 gap-4 text-center max-w-screen-xl">
        <h1 class="text-3xl md:text-6xl font-bold">Bitelife admin panel</h1>
        <h2 class="text-xl font-medium">Administration panel of the Bitelife application to manage all the data
        </h2>
      </div>
    </section>
    <!-- Primary image -->
    <section class="flex -mt-36 px-8 md:px-4">
      <div class="m-auto fadeUp max-w-screen-xl" :class="{'show':showImages}">
        <img alt="timerly main" class="m-auto" src="@/assets/portfolio/bitelife-admin/bitelife-admin-recipes.webp">
      </div>
    </section>
    <!-- IDEA -->
    <section class="flex w-full py-20 px-4 mt-16 text-white">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl text-center m-auto">
        <h3 class=" text-5xl md:text-6xl font-bold">The idea</h3>
        <span class="leading-7 text-xl py-8 max-w-screen-md">The basic idea starts from the need to have an administration panel available to manage all the data on the database with a simple and intuitive interface. It allows the total management of users, subscriptions and everything related to recipes and the calculation of the food plan</span>
      </div>
    </section>
    <!-- STACK -->
    <section class="flex w-full py-20 px-4  bg-white text-theme-dark">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl m-auto">
        <h3 class="text-center text-5xl md:text-6xl font-bold">Stack & Tools</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-8 gap-8 sm:gap-10 md:gap-16">
          <TechCard title="Vue JS" icon="vuejs" />
          <TechCard title="Laravel" icon="laravel" />
          <TechCard title="MySQL" icon="mysql" />
          <TechCard title="Forge" icon="laravelforge" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import TechCard from "@/components/card/TechCard";

export default {
  name: "bitelifeAdmin",
  components: {TechCard},
  setup() {

    const showImages = ref(false)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showImages.value = true;
      }, 100);
    })

    return {
      showImages
    }
  }
}
</script>

<style lang="scss" scoped>
.bitelife-admin-page{

  &__hero{
    @apply flex w-full;
    padding: 15rem 1rem;
    background-color: #102A43;
  }
}
</style>